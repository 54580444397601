import process from 'process';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import './index.css';

window.process = process;

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <Router>
        <HelmetProvider>
            <App/>
        </HelmetProvider>
    </Router>
);
