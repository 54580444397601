import { FaTrash } from "@react-icons/all-files/fa/FaTrash";
import { addMinutes, format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '../components/ThemeContext';
import { TimerAPIUrls } from '../utils/APIUrls';
import { getCurrentButtonStyles } from '../utils/Common';
import { getCookie } from '../utils/Cookies';
import { calculateBackgroundStyle, getBackgroundImages, getImageDimensions } from '../utils/ImageUtils';
import { getWebsiteUrl } from '../utils/Url';

// Add this type declaration at the top of the file, after imports
declare global {
    interface Window {
        onYouTubeIframeAPIReady?: () => void;
    }
}

interface Task {
    taskId: string;  
    taskName: string;
    timeLeft: number;
    duration: number; 
}

interface CompletedTask {
    taskId: string;
    taskName: string;
    duration: number;
}

interface Background {
    type: 'color' | 'image' | 'youtube';
    value: string;
    isMuted?: boolean;
}

const RevisionTimer: React.FC = () => {
    const { isDarkMode } = useTheme();
    const [tasks, setTasks] = useState<Task[]>([]);
    const [currentTask, setCurrentTask] = useState<Task | null>(null);
    const [isRunning, setIsRunning] = useState(false);
    const [background, setBackground] = useState<Background>({ 
        type: 'image', 
        value: '/backgrounds/main.jpg' 
    });
    const [newTask, setNewTask] = useState({ name: '', duration: 0 });
    const jwtToken = getCookie('jwt');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [tempBackground, setTempBackground] = useState<Background>(background);
    const [selectedBackgroundType, setSelectedBackgroundType] = useState<'color' | 'image' | 'youtube'>('color');
    const [estimatedFinishTime, setEstimatedFinishTime] = useState<Date | null>(null);
    const [imageOptions, setImageOptions] = useState<string[]>([]);
    const [isLoadingImages, setIsLoadingImages] = useState(true);
    const [backgroundStyle, setBackgroundStyle] = useState({});
    const [isVideoMuted, setIsVideoMuted] = useState(true);
    const [inputError, setInputError] = useState<string>('');
    const [youtubeError, setYoutubeError] = useState<string>('');

    const [completedTasks, setCompletedTasks] = useState<CompletedTask[]>([]);
    const [showCompletedTasks, setShowCompletedTasks] = useState(false);

    const [isCompletedTasksSidebarOpen, setIsCompletedTasksSidebarOpen] = useState(false);
    const [isTasksSidebarOpen, setIsTasksSidebarOpen] = useState(false);

    const [selectedImagePreview, setSelectedImagePreview] = useState<string | null>(null);

    const [youtubeIframe, setYoutubeIframe] = useState<HTMLIFrameElement | null>(null);

    const [isYoutubeReady, setIsYoutubeReady] = useState(false);

    // Update handleForcePlay to be less aggressive
    const handleForcePlay = useCallback(() => {
        if (youtubeIframe && isYoutubeReady) {
            try {
                // Just send one command instead of multiple
                youtubeIframe.contentWindow?.postMessage(JSON.stringify({
                    event: 'command',
                    func: 'playVideo',
                    args: []
                }), '*');
            } catch (error) {
                console.error('Error forcing video play:', error);
            }
        }
    }, [youtubeIframe, isYoutubeReady]);

    const lastUpdateTimeRef = useRef<number>(Date.now());
    const visibilityChangeRef = useRef<((event: Event) => void) | null>(null);

    useEffect(() => {
        const savedTask = localStorage.getItem('currentTask');
        if (savedTask) {
            const parsedTask = JSON.parse(savedTask);
            setCurrentTask(parsedTask);
            if (parsedTask.timeLeft > 0) {
                setIsRunning(false);
                setEstimatedFinishTime(addMinutes(new Date(), Math.ceil(parsedTask.timeLeft / 60)));
            }
        }
    }, []);

    useEffect(() => {
        if (currentTask) {
            localStorage.setItem('currentTask', JSON.stringify(currentTask));
        } else {
            localStorage.removeItem('currentTask');
        }
    }, [currentTask]);

    useEffect(() => {
        const loadBackgroundImages = async () => {
            setIsLoadingImages(true);
            try {
                const images = await getBackgroundImages();
                setImageOptions(images);
            } catch (error) {
                console.error('Error loading background images:', error);
                setImageOptions(['/backgrounds/main.jpeg']);
            }
            setIsLoadingImages(false);
        };
        
        loadBackgroundImages();
    }, []);

    const isSaveButtonDisabled = () => {
        if (selectedBackgroundType === 'image' && !tempBackground.value) {
            return true;
        }
        if (selectedBackgroundType === 'youtube' && (!tempBackground.value || tempBackground.type !== 'youtube')) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const response = await fetch(TimerAPIUrls.ACTIVE_TASKS, {
                    headers: { 'Authorization': `Bearer ${jwtToken}` }
                });
                const data = await response.json();
                
                const savedTaskString = localStorage.getItem('currentTask');
                if (savedTaskString) {
                    const savedTask = JSON.parse(savedTaskString);
                    setCurrentTask(savedTask);
                    setTasks(data.filter((task: Task) => task.taskId !== savedTask.taskId));
                    if (savedTask.timeLeft > 0) {
                        setEstimatedFinishTime(addMinutes(new Date(), Math.ceil(savedTask.timeLeft / 60)));
                    }
                } else {
                    setTasks(data);
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchTasks();
    }, [jwtToken]);

    useEffect(() => {
        if (isRunning && currentTask) {
            const handleVisibilityChange = () => {
                if (document.hidden) {
                    // Store the time when the page becomes hidden
                    lastUpdateTimeRef.current = Date.now();
                } else {
                    // When page becomes visible again, calculate missed time
                    const now = Date.now();
                    const elapsedSeconds = Math.floor((now - lastUpdateTimeRef.current) / 1000);
                    
                    if (elapsedSeconds > 0 && currentTask) {
                        setCurrentTask(prev => {
                            if (!prev) return prev;
                            
                            const newTimeLeft = Math.max(0, prev.timeLeft - elapsedSeconds);
                            const updatedTask = { ...prev, timeLeft: newTimeLeft };
                            
                            // Store the updated task in localStorage
                            localStorage.setItem('currentTask', JSON.stringify(updatedTask));
                            
                            if (newTimeLeft === 0) {
                                setIsRunning(false);
                            }
                            
                            return updatedTask;
                        });
                    }
                }
            };

            // Store the handler in the ref for cleanup
            visibilityChangeRef.current = handleVisibilityChange;
            
            // Add visibility change listener
            document.addEventListener('visibilitychange', handleVisibilityChange);

            // Set up a backup interval for when the page is visible
            const interval = setInterval(() => {
                if (!document.hidden && currentTask.timeLeft > 0) {
                    setCurrentTask(prev => {
                        if (prev && prev.timeLeft > 0) {
                            const updatedTask = { ...prev, timeLeft: prev.timeLeft - 1 };
                            localStorage.setItem('currentTask', JSON.stringify(updatedTask));
                            return updatedTask;
                        }
                        setIsRunning(false);
                        return prev;
                    });
                }
            }, 1000);

            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
                clearInterval(interval);
            };
        }
    }, [isRunning, currentTask]);

    useEffect(() => {
        const loadBackgroundPreference = async () => {
            try {
                const response = await fetch(TimerAPIUrls.BACKGROUND, {
                    headers: { 'Authorization': `Bearer ${jwtToken}` }
                });

                if (!response.ok || response.status === 204) {
                    const defaultBackground = { type: 'image' as const, value: '/backgrounds/main.jpg' };
                    setBackground(defaultBackground);
                    setTempBackground(defaultBackground);
                    const { width, height } = await getImageDimensions(defaultBackground.value);
                    const style = calculateBackgroundStyle(
                        width,
                        height,
                        window.innerWidth,
                        window.innerHeight
                    );
                    setBackgroundStyle({
                        ...style,
                        backgroundImage: `url(${defaultBackground.value})`,
                        backgroundSize: 'cover'
                    });
                    return;
                }

                const data = await response.json();
                console.log('Loaded background from API:', data);

                // Handle YouTube background with muting
                if (data.preferenceType.toLowerCase() === 'youtube') {
                    let videoUrl = data.value;
                    // Add mute parameter if it's not already present
                    if (!videoUrl.includes('mute=1')) {
                        const separator = videoUrl.includes('?') ? '&' : '?';
                        videoUrl = `${videoUrl}${separator}mute=1`;
                    }
                    
                    const newBackground: Background = {
                        type: 'youtube',
                        value: videoUrl,
                        isMuted: true // Always start muted
                    };
                    
                    setBackground(newBackground);
                    setTempBackground(newBackground);
                    setIsVideoMuted(true);
                    return;
                }

                // Handle other background types
                const backgroundType = data.preferenceType.toLowerCase();
                if (backgroundType !== 'color' && backgroundType !== 'image' && backgroundType !== 'youtube') {
                    throw new Error('Invalid background type');
                }

                const newBackground: Background = {
                    type: backgroundType as 'color' | 'image' | 'youtube',
                    value: data.value,
                };

                setBackground(newBackground);
                setTempBackground(newBackground);

                if (newBackground.type === 'image') {
                    const { width, height } = await getImageDimensions(newBackground.value);
                    const style = calculateBackgroundStyle(
                        width,
                        height,
                        window.innerWidth,
                        window.innerHeight
                    );
                    setBackgroundStyle({
                        ...style,
                        backgroundImage: `url(${newBackground.value})`,
                        backgroundSize: 'cover'
                    });
                } else if (newBackground.type === 'color') {
                    setBackgroundStyle({
                        backgroundColor: newBackground.value,
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        width: '100vw',
                        height: '100vh'
                    });
                }
                return;
            } catch (error) {
                console.error('Error loading background from API:', error);
            }

            const defaultBackground = { type: 'image' as const, value: '/backgrounds/main.jpg' };
            setBackground(defaultBackground);
            setTempBackground(defaultBackground);
            const { width, height } = await getImageDimensions(defaultBackground.value);
            const style = calculateBackgroundStyle(
                width,
                height,
                window.innerWidth,
                window.innerHeight
            );
            setBackgroundStyle({
                ...style,
                backgroundImage: `url(${defaultBackground.value})`,
                backgroundSize: 'cover'
            });
        };

        loadBackgroundPreference();
    }, [jwtToken]);

    useEffect(() => {
        const handleResize = async () => {
            if (background.type === 'image') {
                const { width, height } = await getImageDimensions(background.value);
                const style = calculateBackgroundStyle(
                    width,
                    height,
                    window.innerWidth,
                    window.innerHeight
                );
                setBackgroundStyle(style);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [background]);

    useEffect(() => {
        const fetchCompletedTasks = async () => {
            try {
                const response = await fetch(TimerAPIUrls.COMPLETED_TASKS, {
                    headers: { 'Authorization': `Bearer ${jwtToken}` }
                });
                const data = await response.json();
                setCompletedTasks(data);
            } catch (error) {
                console.error('Error fetching completed tasks:', error);
            }
        };

        if (showCompletedTasks) {
            fetchCompletedTasks();
        }
    }, [jwtToken, showCompletedTasks]);

    useEffect(() => {
        const loadCurrentTask = async () => {
            try {
                const response = await fetch(TimerAPIUrls.CURRENT_TASK, {
                    headers: { 'Authorization': `Bearer ${jwtToken}` }
                });

                if (response.ok) {
                    const data = await response.json();
                    const taskWithSeconds = {
                        taskId: data.taskId.toString(),
                        taskName: data.taskName,
                        timeLeft: data.timeLeft * 60,
                        duration: data.duration
                    };
                    setCurrentTask(taskWithSeconds);
                    setEstimatedFinishTime(addMinutes(new Date(), Math.ceil(data.timeLeft)));
                }
            } catch (error) {
                console.error('Error loading current task:', error);
            }
        };

        loadCurrentTask();
    }, [jwtToken]);

    const handleSaveBackground = async () => {
        try {
            let backgroundValue = tempBackground.value;
            
            if (tempBackground.type === 'youtube') {
                const embedUrl = validateYoutubeUrl(tempBackground.value);
                if (!embedUrl) return;
                
                const separator = embedUrl.includes('?') ? '&' : '?';
                backgroundValue = `${embedUrl}${separator}mute=${isVideoMuted ? '1' : '0'}`;
            }

            const backgroundData = {
                backgroundType: getApiPreferenceType(tempBackground.type),
                backgroundValue: tempBackground.type === 'color' && !backgroundValue.startsWith('#') 
                    ? `#${backgroundValue}` 
                    : backgroundValue,
                muted: tempBackground.type === 'youtube' ? isVideoMuted : undefined
            };

            const response = await fetch(TimerAPIUrls.BACKGROUND, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify(backgroundData)
            });

            if (!response.ok) {
                throw new Error('Failed to save background');
            }

            const newBackground: Background = {
                type: tempBackground.type,
                value: backgroundValue,
                isMuted: tempBackground.type === 'youtube' ? isVideoMuted : undefined
            };
            
            setBackground(newBackground);
            setSelectedImagePreview(null);
            setIsSidebarOpen(false);

            if (tempBackground.type === 'image') {
                const { width, height } = await getImageDimensions(backgroundValue);
                setBackgroundStyle({
                    ...calculateBackgroundStyle(width, height, window.innerWidth, window.innerHeight),
                    backgroundImage: `url(${backgroundValue})`,
                    backgroundSize: 'cover',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: '100vw',
                    height: '100vh'
                });
            }
        } catch (error) {
            console.error('Error saving background:', error);
        }
    };

    const handleAddTask = async () => {
        setInputError('');

        if (!newTask.name.trim()) {
            setInputError('Task name is required');
            return;
        }
        if (!newTask.duration) {
            setInputError('Duration is required');
            return;
        }
        if (newTask.duration <= 0) {
            setInputError('Duration must be greater than 0');
            return;
        }

        try {
            await fetch(TimerAPIUrls.TASKS, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify({
                    name: newTask.name,
                    duration: newTask.duration
                })
            });
            
            const response = await fetch(TimerAPIUrls.ACTIVE_TASKS, {
                headers: { 'Authorization': `Bearer ${jwtToken}` }
            });
            const data = await response.json();
            setTasks(data);
            setNewTask({ name: '', duration: 0 });
            setInputError('');
        } catch (error) {
            console.error('Error adding task:', error);
            setInputError('Failed to add task');
        }
    };

    const handleStartTask = async (task: Task) => {
        try {
            const response = await fetch(TimerAPIUrls.SET_CURRENT_TASK(task.taskId), {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${jwtToken}` }
            });

            if (!response.ok) {
                if (response.status === 409) {
                    setInputError('You already have an active task.');
                    return;
                }
                throw new Error('Failed to set current task');
            }

            const taskWithSeconds = { 
                ...task, 
                timeLeft: task.timeLeft * 60
            };
            setCurrentTask(taskWithSeconds);
            setIsRunning(true);
            setEstimatedFinishTime(addMinutes(new Date(), task.timeLeft));
            setIsTasksSidebarOpen(false);
            setTasks(tasks.filter(t => t.taskId !== task.taskId));
        } catch (error) {
            console.error('Error starting task:', error);
            setInputError('Failed to start task');
        }
    };

    const handlePause = async () => {
        setIsRunning(false);
        if (currentTask) {
            try {
                // Calculate total minutes completed from the original duration and current time left
                const timeLeftSeconds = currentTask.timeLeft ?? 0;
                const totalDurationSeconds = currentTask.duration * 60;
                const secondsCompleted = totalDurationSeconds - timeLeftSeconds;
                const minutesCompleted = Math.ceil(secondsCompleted / 60);
                
                await fetch(TimerAPIUrls.TASK(currentTask.taskId), {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`
                    },
                    body: new URLSearchParams({
                        timeDone: String(minutesCompleted)
                    })
                });
            } catch (error) {
                console.error('Error saving progress:', error);
            }
        }
    };

    const handleEndTask = async () => {
        if (!currentTask) return;

        try {
            await fetch(TimerAPIUrls.COMPLETE_TASK(currentTask.taskId), {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });

            const completedResponse = await fetch(TimerAPIUrls.COMPLETED_TASKS, {
                headers: { 'Authorization': `Bearer ${jwtToken}` }
            });
            const completedData = await completedResponse.json();
            setCompletedTasks(completedData);

            const activeResponse = await fetch(TimerAPIUrls.ACTIVE_TASKS, {
                headers: { 'Authorization': `Bearer ${jwtToken}` }
            });
            const activeData = await activeResponse.json();
            setTasks(activeData);

            setCurrentTask(null);
            setIsRunning(false);
            setEstimatedFinishTime(null);
            localStorage.removeItem('currentTask');
            
            setIsCompletedTasksSidebarOpen(true);
            setShowCompletedTasks(true);
        } catch (error) {
            console.error('Error completing task:', error);
        }
    };

    const handleBackgroundChange = async (type: 'color' | 'image' | 'youtube', value: string) => {
        let newBackground: Background = {
            type,
            value,
            ...(type === 'youtube' ? { isMuted: isVideoMuted } : {})
        };

        if (type === 'youtube') {
            const videoId = validateYoutubeUrl(value);
            if (!videoId) return;
            // Add enablejsapi=1 to allow JavaScript control
            const embedUrl = `${videoId}?autoplay=1&controls=0&showinfo=0&rel=0&loop=1&enablejsapi=1`;
            newBackground.value = embedUrl;
        }

        setTempBackground(newBackground);
        setSelectedBackgroundType(type);
        if (type === 'image') {
            setSelectedImagePreview(value);
            try {
                const { width, height } = await getImageDimensions(value);
                const style = calculateBackgroundStyle(
                    width,
                    height,
                    window.innerWidth,
                    window.innerHeight
                );
                setBackgroundStyle({
                    ...style,
                    backgroundImage: `url(${value})`,
                    backgroundSize: 'cover',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: '100vw',
                    height: '100vh'
                });
            } catch (error) {
                console.error('Error calculating background style:', error);
            }
        }
    };

    useEffect(() => {
        const savedTask = localStorage.getItem('currentTask');
        if (savedTask) {
            const parsedTask = JSON.parse(savedTask);
            setCurrentTask(parsedTask);
            if (parsedTask.timeLeft > 0) {
                setIsRunning(false);
                setEstimatedFinishTime(addMinutes(new Date(), Math.ceil(parsedTask.timeLeft / 60)));
            }
        }
    }, []);

    useEffect(() => {
        if (currentTask) {
            localStorage.setItem('currentTask', JSON.stringify(currentTask));
        } else {
            localStorage.removeItem('currentTask');
        }
    }, [currentTask]);

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const response = await fetch(TimerAPIUrls.ACTIVE_TASKS, {
                    headers: { 'Authorization': `Bearer ${jwtToken}` }
                });
                const data = await response.json();
                
                const savedTaskString = localStorage.getItem('currentTask');
                if (savedTaskString) {
                    const savedTask = JSON.parse(savedTaskString);
                    setTasks(data.filter((task: Task) => task.taskId !== savedTask.taskId));
                } else {
                    setTasks(data);
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchTasks();
    }, [jwtToken]);

    const getContrastColor = (background: Background) => {
        if (background.type === 'color') {
            const hex = background.value.replace('#', '');
            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);
            const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
            return luminance > 0.5 ? '#000000' : '#ffffff';
        }
        return '#ffffff';
    };

    const formatTime = (seconds: number): string => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        if (hours > 0) {
            return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
        }
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    // Update handleMuteToggle function
    const handleMuteToggle = async () => {
        const newMutedState = !isVideoMuted;
        setIsVideoMuted(newMutedState);

        try {
            if (background.type === 'youtube' && youtubeIframe) {
                // Send mute/unmute command directly to the iframe
                youtubeIframe.contentWindow?.postMessage(JSON.stringify({
                    event: 'command',
                    func: newMutedState ? 'mute' : 'unMute',
                    args: []
                }), '*');

                const updatedBackground = {
                    ...background,
                    isMuted: newMutedState
                };
                setBackground(updatedBackground);
                setTempBackground(updatedBackground);
            }

            const response = await fetch(TimerAPIUrls.BACKGROUND_MUTE, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: new URLSearchParams({
                    muted: String(newMutedState)
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update mute preference');
            }
        } catch (error) {
            console.error('Error updating mute preference:', error);
            // Revert the state
            setIsVideoMuted(!newMutedState);
            if (background.type === 'youtube' && youtubeIframe) {
                // Revert the iframe state
                youtubeIframe.contentWindow?.postMessage(JSON.stringify({
                    event: 'command',
                    func: !newMutedState ? 'mute' : 'unMute',
                    args: []
                }), '*');
            }
        }
    };

    const handleDeleteTask = async (taskId: string, isCompleted: boolean = false) => {
        try {
            const numericId = parseInt(taskId);
            if (isNaN(numericId)) {
                console.error('Invalid task ID format');
                return;
            }

            const response = await fetch(TimerAPIUrls.TASK(numericId.toString()), {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });

            if (response.ok) {
                if (isCompleted) {
                    setCompletedTasks(completedTasks.filter(t => t.taskId !== taskId));
                } else {
                    setTasks(tasks.filter(t => t.taskId !== taskId));
                }
            } else {
                const errorData = await response.json();
                console.error('Failed to delete task:', errorData);
            }
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };

    useEffect(() => {
        if (isSidebarOpen) {
            setSelectedBackgroundType(background.type);
            setTempBackground(background);
            // Sync mute state from current background
            if (background.type === 'youtube') {
                setIsVideoMuted(background.isMuted ?? true);
            }
        }
    }, [isSidebarOpen, background]);

    const validateYoutubeUrl = (url: string): string | null => {
        setYoutubeError('');
        
        const patterns = [
            /^[a-zA-Z0-9_-]{11}$/, // Direct video ID
            /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/, // Full URLs
        ];

        let videoId = null;
        
        if (patterns[0].test(url)) {
            videoId = url;
        } else {
            for (const pattern of patterns) {
                const match = url.match(pattern);
                if (match && match[1]) {
                    videoId = match[1];
                    break;
                }
            }
        }

        if (!videoId) {
            setYoutubeError('Invalid YouTube URL or video ID');
            return null;
        }

        // Return the complete embed URL with all necessary parameters
        return `https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0&showinfo=0&rel=0&loop=1&playlist=${videoId}${isVideoMuted ? '&mute=1' : ''}`;
    };

    const getApiPreferenceType = (type: 'color' | 'image' | 'youtube'): string => {
        return type.toLowerCase();
    };

    const [isInitialLoading, setIsInitialLoading] = useState(true);

    useEffect(() => {
        const loadBackgroundPreference = async () => {
            setIsInitialLoading(true);
            try {
                const response = await fetch(TimerAPIUrls.BACKGROUND, {
                    headers: { 'Authorization': `Bearer ${jwtToken}` }
                });

                if (!response.ok || response.status === 204) {
                    const defaultBackground = { type: 'image' as const, value: '/backgrounds/main.jpg' };
                    setBackground(defaultBackground);
                    setTempBackground(defaultBackground);
                    const { width, height } = await getImageDimensions(defaultBackground.value);
                    const style = calculateBackgroundStyle(
                        width,
                        height,
                        window.innerWidth,
                        window.innerHeight
                    );
                    setBackgroundStyle({
                        ...style,
                        backgroundImage: `url(${defaultBackground.value})`,
                        backgroundSize: 'cover'
                    });
                    setIsInitialLoading(false);
                    return;
                }

                const data = await response.json();
                console.log('Loaded background from API:', data);

                let newValue = data.value;
                // Add mute parameter to YouTube URL if isMuted is true
                if (data.preferenceType.toLowerCase() === 'youtube' && data.muted) {
                    const separator = data.value.includes('?') ? '&' : '?';
                    newValue = `${data.value}${separator}mute=1`;
                }

                const newBackground = {
                    type: data.preferenceType.toLowerCase() as 'color' | 'image' | 'youtube',
                    value: newValue,
                    isMuted: data.muted
                };

                setBackground(newBackground);
                setTempBackground(newBackground);
                setIsVideoMuted(data.muted);

                if (newBackground.type === 'image') {
                    const { width, height } = await getImageDimensions(newBackground.value);
                    const style = calculateBackgroundStyle(
                        width,
                        height,
                        window.innerWidth,
                        window.innerHeight
                    );
                    setBackgroundStyle({
                        ...style,
                        backgroundImage: `url(${newBackground.value})`,
                        backgroundSize: 'cover'
                    });
                } else if (newBackground.type === 'color') {
                    setBackgroundStyle({
                        backgroundColor: newBackground.value,
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        width: '100vw',
                        height: '100vh'
                    });
                }
            } catch (error) {
                console.error('Error loading background from API:', error);
                const defaultBackground = { type: 'image' as const, value: '/backgrounds/main.jpg' };
                setBackground(defaultBackground);
                setTempBackground(defaultBackground);
            } finally {
                setIsInitialLoading(false);
            }
        };

        loadBackgroundPreference();
    }, [jwtToken]);

    const handleDeleteCurrentTask = async () => {
        if (!currentTask) return;

        try {
            const response = await fetch(TimerAPIUrls.TASK(currentTask.taskId), {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });

            if (response.ok) {
                setCurrentTask(null);
                setIsRunning(false);
                setEstimatedFinishTime(null);
                localStorage.removeItem('currentTask');
            } else {
                console.error('Failed to delete current task');
            }
        } catch (error) {
            console.error('Error deleting current task:', error);
        }
    };

    useEffect(() => {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
    }, []);

    // Replace the existing YouTube autoplay effects with this single one
    useEffect(() => {
        if (background.type === 'youtube' && isYoutubeReady) {
            // Initial play attempt
            const timer = setTimeout(() => {
                handleForcePlay();
            }, 1000);

            // Handle YouTube state changes
            const handleYouTubeMessage = (event: MessageEvent) => {
                try {
                    const data = JSON.parse(event.data);
                    // Only try to restart if video is actually paused (state 2)
                    if (data.event === 'onStateChange' && data.info === 2) {
                        handleForcePlay();
                    }
                } catch (e) {
                    // Ignore parsing errors
                }
            };

            window.addEventListener('message', handleYouTubeMessage);
            return () => {
                clearTimeout(timer);
                window.removeEventListener('message', handleYouTubeMessage);
            };
        }
    }, [background.type, isYoutubeReady, handleForcePlay]);

    useEffect(() => {
        // Define the callback for when YouTube API is ready
        window.onYouTubeIframeAPIReady = () => {
            setIsYoutubeReady(true);
        };

        // Load YouTube API script
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

        return () => {
            window.onYouTubeIframeAPIReady = undefined;
        };
    }, []);

    return (
        <div className="h-screen overflow-hidden">
            <Helmet>
                <title>Revise Wizard - Timer</title>
                <meta name="description" content="Revision Timer - Stay focused and track your study sessions" />
                <link rel="canonical" href={getWebsiteUrl() + '/timer'} />
                <style>
                    {`
                        body {
                            overflow: hidden;
                            position: fixed;
                            width: 100%;
                            height: 100%;
                        }
                    `}
                </style>
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap" rel="stylesheet" />
            </Helmet>

            {isInitialLoading && (
                <div className="fixed inset-0 z-[300] flex flex-col items-center justify-center bg-gradient-to-b from-gray-900 to-gray-800">
                    <div className="text-center">
                        <h1 className="mb-8 text-3xl font-bold text-white">
                            Building Revision Timer
                            <span className="animate-pulse">...</span>
                        </h1>
                        <div className="w-64 h-2 mx-auto mb-4 overflow-hidden bg-gray-700 rounded-full">
                            <div className="h-full transition-all duration-300 bg-gradient-to-r from-blue-500 to-purple-500 animate-loadingBar" />
                        </div>
                        <p className="text-gray-400">Please wait a moment</p>
                    </div>
                </div>
            )}

            <div className="fixed inset-0 min-h-screen overflow-hidden" style={{
                ...backgroundStyle,
                ...(background.type === 'color' ? { backgroundColor: background.value } : {}),
                backgroundImage: background.type === 'image' ? `url(${background.value})` : 'none',
                backdropFilter: 'none',
                WebkitBackdropFilter: 'none'
            }} />

            <div className="relative z-10 flex flex-col min-h-screen overflow-hidden">
                {background.type === 'youtube' && (
                    <iframe
                        ref={(ref) => setYoutubeIframe(ref)}
                        className="fixed top-0 left-0 z-0 w-screen h-screen pointer-events-none"
                        src={`${background.value}${background.value.includes('?') ? '&' : '?'}mute=1&playsinline=1&autoplay=1&enablejsapi=1`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        style={{
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            width: '177.77777778vh', /* 16:9 aspect ratio */
                            height: '56.25vw', /* 16:9 aspect ratio */
                            minWidth: '100%',
                            minHeight: '100%',
                            transform: 'translate(-50%, -50%)',
                            border: 'none',
                            objectFit: 'cover',
                            zIndex: 0
                        }}
                        title="background"
                        frameBorder="0"
                    />
                )}

                <div className="fixed z-[200] bottom-24 left-1/2 transform -translate-x-1/2 flex gap-4">
                    <div className="flex flex-col items-center">
                        <button 
                            onClick={() => setIsSidebarOpen(true)}
                            className="p-3 mb-1 transition-all border-2 border-white rounded-full cursor-pointer pointer-events-auto border-opacity-20 backdrop-blur-sm bg-black/30 hover:bg-black/50 hover:scale-105"
                            style={{
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                            }}
                            title="Settings"
                        >
                            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                        </button>
                        <span className="text-xs text-white text-opacity-90 backdrop-blur-sm">Settings</span>
                    </div>

                    <div className="flex flex-col items-center">
                        <button 
                            onClick={() => setIsTasksSidebarOpen(true)}
                            className="p-3 mb-1 transition-all border-2 border-white rounded-full cursor-pointer pointer-events-auto border-opacity-20 backdrop-blur-sm bg-black/30 hover:bg-black/50 hover:scale-105"
                            style={{
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                            }}
                            title="Tasks"
                        >
                            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                            </svg>
                        </button>
                        <span className="text-xs text-white text-opacity-90 backdrop-blur-sm">Tasks</span>
                    </div>

                    <div className="flex flex-col items-center">
                        <button 
                            onClick={() => {
                                setIsCompletedTasksSidebarOpen(true);
                                setShowCompletedTasks(true);
                            }}
                            className="p-3 mb-1 transition-all border-2 border-white rounded-full cursor-pointer pointer-events-auto border-opacity-20 backdrop-blur-sm bg-black/30 hover:bg-black/50 hover:scale-105"
                            style={{
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                            }}
                            title="Completed Tasks"
                        >
                            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2M9 12l2 2 4-4" />
                            </svg>
                        </button>
                        <span className="text-xs text-white text-opacity-90 backdrop-blur-sm">Completed</span>
                    </div>

                    {background.type === 'youtube' && (
                        <div className="flex flex-col items-center">
                            <button 
                                onClick={handleForcePlay}
                                className="p-3 mb-1 transition-all border-2 border-white rounded-full cursor-pointer pointer-events-auto border-opacity-20 backdrop-blur-sm bg-black/30 hover:bg-black/50 hover:scale-105"
                                style={{
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                                }}
                                title="Force Play Video"
                            >
                                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </button>
                            <span className="text-xs text-white text-opacity-90 backdrop-blur-sm">Play Video</span>
                        </div>
                    )}
                </div>

                {currentTask && (
                    <div className="fixed inset-0 z-40 flex items-center justify-center">
                        <div className="p-8 text-center transition-all duration-300 rounded-2xl bg-opacity-20 backdrop-blur-md hover:backdrop-blur-lg"
                             style={{
                                 background: 'rgba(0, 0, 0, 0.3)',
                                 textShadow: background.type !== 'color' ? '2px 2px 4px rgba(0, 0, 0, 0.8)' : 'none',
                                 color: getContrastColor(background),
                                 boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
                                 border: '1px solid rgba(255, 255, 255, 0.1)',
                             }}>
                            <div className="flex justify-end">
                                <button
                                    onClick={handleDeleteCurrentTask}
                                    className="p-2 mb-2 text-white transition-all rounded-full bg-red-500/50 hover:bg-red-500/70"
                                    title="Delete current task"
                                >
                                    <FaTrash className="w-4 h-4" />
                                </button>
                            </div>
                            
                            <h2 className="mb-4 text-4xl font-bold tracking-tight font-inter">
                                {currentTask.taskName}
                            </h2>
                            
                            <div className={`mb-6 text-7xl font-bold tracking-tighter transition-all duration-300 transform font-inter tabular-nums text-white
                                ${isRunning ? 'scale-105' : 'scale-100'}`}
                                 style={{
                                     WebkitTextStroke: '1px rgba(255, 255, 255, 0.1)',
                                 }}>
                                <span className="inline-block transition-all duration-300">
                                    {formatTime(currentTask.timeLeft ?? 0)}
                                </span>
                            </div>
                            
                            {estimatedFinishTime && (
                                <div className="mb-6 text-xl font-medium opacity-80 font-inter">
                                    Estimated finish at {format(estimatedFinishTime, 'HH:mm')}
                                </div>
                            )}
                            
                            <div className="flex justify-center gap-4">
                                <button
                                    onClick={() => isRunning ? handlePause() : setIsRunning(true)}
                                    className={`px-8 py-3 text-lg font-medium transition-all rounded-xl backdrop-blur-sm
                                        ${isRunning 
                                            ? 'bg-white/10 hover:bg-white/20 text-white' 
                                            : 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white'
                                        }
                                        transform hover:scale-105 active:scale-95`}
                                >
                                    {isRunning ? 'Pause' : 'Resume'}
                                </button>
                                <button
                                    onClick={handleEndTask}
                                    className="px-8 py-3 text-lg font-medium text-white transition-all transform rounded-xl bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700 hover:scale-105 active:scale-95"
                                >
                                    End Task
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <div 
                    className={`fixed top-[64px] right-0 h-[calc(100vh-64px)] w-full md:w-80 bg-white/95 dark:bg-gray-800/95 
                               transform transition-transform duration-300 ease-in-out z-[201]
                               ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="h-full p-6 overflow-y-auto">
                        <div className="flex items-center">
                            <button 
                                onClick={() => setIsSidebarOpen(false)}
                                className="p-2 text-gray-800 transition-colors rounded-full dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                            >
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        
                        <div className="mt-4 text-gray-800 dark:text-white">
                            <div className="mb-8">
                                <h2 className="mb-4 text-2xl font-bold">Background</h2>
                                <div className="space-y-4">
                                    <div className="grid grid-cols-3 gap-2">
                                        <button
                                            onClick={() => setSelectedBackgroundType('color')}
                                            className={`px-4 py-3 text-sm font-medium rounded-lg transition-all duration-200 ${
                                                selectedBackgroundType === 'color' 
                                                    ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white shadow-lg transform scale-105' 
                                                    : 'bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600'
                                            }`}
                                        >
                                            Color
                                        </button>
                                        <button
                                            onClick={() => setSelectedBackgroundType('image')}
                                            className={`px-4 py-3 text-sm font-medium rounded-lg transition-all duration-200 ${
                                                selectedBackgroundType === 'image' 
                                                    ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white shadow-lg transform scale-105' 
                                                    : 'bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600'
                                            }`}
                                        >
                                            Image
                                        </button>
                                        <button
                                            onClick={() => setSelectedBackgroundType('youtube')}
                                            className={`px-4 py-3 text-sm font-medium rounded-lg transition-all duration-200 ${
                                                selectedBackgroundType === 'youtube' 
                                                    ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white shadow-lg transform scale-105' 
                                                    : 'bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600'
                                            }`}
                                        >
                                            YouTube
                                        </button>
                                    </div>

                                    {selectedBackgroundType === 'color' && (
                                        <div className="p-4 rounded-lg bg-gray-50 dark:bg-gray-700/50 backdrop-blur-sm">
                                            <div className="flex items-center gap-4">
                                                <input
                                                    type="color"
                                                    value={tempBackground.type === 'color' ? tempBackground.value : '#ffffff'}
                                                    onChange={(e) => handleBackgroundChange('color', e.target.value)}
                                                    className="w-16 h-16 bg-transparent border-0 rounded-lg cursor-pointer"
                                                />
                                                <span className="text-sm opacity-75">Pick a color for your background</span>
                                            </div>
                                        </div>
                                    )}

                                    {selectedBackgroundType === 'image' && (
                                        <div className="space-y-4">
                                            {isLoadingImages ? (
                                                <div className="flex items-center justify-center h-32 rounded-lg bg-gray-50 dark:bg-gray-700/50">
                                                    <span className="text-sm opacity-75">Loading images...</span>
                                                </div>
                                            ) : (
                                                <div className="grid grid-cols-1 gap-4">
                                                    {imageOptions.map((image, index) => (
                                                        <div 
                                                            key={index} 
                                                            className={`relative h-32 overflow-hidden rounded-lg shadow-lg transition-all duration-200 ${
                                                                selectedImagePreview === image 
                                                                    ? 'ring-4 ring-blue-500 ring-offset-2 dark:ring-offset-gray-800 transform scale-[1.02]' 
                                                                    : 'hover:ring-2 hover:ring-blue-400 hover:ring-offset-2 dark:hover:ring-offset-gray-800'
                                                            }`}
                                                        >
                                                            <img
                                                                src={image}
                                                                alt={`Background ${index + 1}`}
                                                                className="object-cover w-full h-full transition-transform duration-200 cursor-pointer"
                                                                onClick={() => handleBackgroundChange('image', image)}
                                                                onError={(e) => {
                                                                    console.error(`Failed to load image: ${image}`);
                                                                    e.currentTarget.style.display = 'none';
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {selectedBackgroundType === 'youtube' && (
                                        <div className="p-4 space-y-4 rounded-lg bg-gray-50 dark:bg-gray-700/50 backdrop-blur-sm">
                                            <div className="space-y-2">
                                                <label className="text-sm font-medium opacity-75">YouTube Video URL or ID</label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter video ID or URL"
                                                    className="w-full p-3 border-0 rounded-lg shadow-inner bg-white/10 backdrop-blur-sm focus:ring-2 focus:ring-blue-500 dark:bg-gray-600/50"
                                                    onChange={(e) => handleBackgroundChange('youtube', e.target.value)}
                                                />
                                                {youtubeError && (
                                                    <p className="text-sm text-red-500">{youtubeError}</p>
                                                )}
                                                <p className="text-xs opacity-60">
                                                    Accept formats:<br />
                                                    - Full URL (e.g., https://www.youtube.com/watch?v=dQw4w9WgXcQ)<br />
                                                    - Short URL (e.g., https://youtu.be/dQw4w9WgXcQ)<br />
                                                    - Video ID (e.g., dQw4w9WgXcQ)
                                                </p>
                                            </div>
                                            {background.type === 'youtube' && (
                                                <div className="flex items-center gap-3 p-2">
                                                    <input
                                                        type="checkbox"
                                                        id="muteVideo"
                                                        checked={isVideoMuted}
                                                        onChange={handleMuteToggle}
                                                        className="w-5 h-5 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
                                                    />
                                                    <label htmlFor="muteVideo" className="text-sm">
                                                        Mute Video
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    <button
                                        onClick={handleSaveBackground}
                                        disabled={isSaveButtonDisabled()}
                                        className={`w-full px-6 py-3 rounded-lg font-medium transition-all duration-200 ${
                                            isSaveButtonDisabled()
                                                ? 'bg-gray-300 dark:bg-gray-600 cursor-not-allowed opacity-50'
                                                : 'bg-gradient-to-r from-blue-500 to-blue-600 text-white shadow-lg hover:shadow-xl hover:scale-[1.02]'
                                        }`}
                                    >
                                        Save Background
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div 
                    className={`fixed top-[64px] right-0 h-[calc(100vh-64px)] w-full md:w-80 bg-white/95 dark:bg-gray-800/95 
                               transform transition-transform duration-300 ease-in-out z-[201]
                               ${isTasksSidebarOpen ? 'translate-x-0' : 'translate-x-full'}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="h-full p-6 overflow-y-auto">
                        <div className="flex items-center">
                            <button 
                                onClick={() => setIsTasksSidebarOpen(false)}
                                className="p-2 text-gray-800 transition-colors rounded-full dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                            >
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>

                        <div className="mt-4 text-gray-800 dark:text-white">
                            <div className="mt-8 mb-8">
                                <h2 className="mb-4 text-2xl font-bold">Create Task</h2>
                                <input
                                    type="text"
                                    placeholder="Task name"
                                    value={newTask.name}
                                    onChange={(e) => setNewTask({ ...newTask, name: e.target.value })}
                                    className="w-full p-2 mb-4 border rounded dark:bg-gray-700 dark:text-white dark:border-gray-600"
                                    required
                                />
                                <input
                                    type="number"
                                    placeholder="Duration (minutes)"
                                    value={newTask.duration || ''}
                                    onChange={(e) => setNewTask({ ...newTask, duration: parseInt(e.target.value) || 0 })}
                                    className="w-full p-2 mb-4 border rounded dark:bg-gray-700 dark:text-white dark:border-gray-600"
                                    min="1"
                                    required
                                />
                                {inputError && (
                                    <div className="p-2 mb-4 text-sm text-red-600 bg-red-100 rounded dark:bg-red-900/30 dark:text-red-400">
                                        {inputError}
                                    </div>
                                )}
                                <button
                                    onClick={handleAddTask}
                                    className={`w-full px-4 py-2 text-white rounded ${
                                        !newTask.name.trim() || !newTask.duration || newTask.duration <= 0
                                            ? 'bg-gray-400 cursor-not-allowed'
                                            : ''
                                    }`}
                                    style={!newTask.name.trim() || !newTask.duration || newTask.duration <= 0 ? {} : getCurrentButtonStyles(isDarkMode)}
                                    disabled={!newTask.name.trim() || !newTask.duration || newTask.duration <= 0}
                                >
                                    Add Task
                                </button>
                            </div>

                            {tasks.length > 0 && (
                                <div className="mb-8">
                                    <h2 className="mb-4 text-2xl font-bold">Your Tasks</h2>
                                    <div className="space-y-3">
                                        {tasks.map(task => (
                                            <div 
                                                key={task.taskId} 
                                                className="p-4 transition-all duration-200 rounded-lg bg-white/10 backdrop-blur-sm hover:bg-white/20 dark:bg-gray-700/50 dark:hover:bg-gray-700/70"
                                            >
                                                <div className="flex flex-col">
                                                    <span className="mb-1 text-lg font-semibold">{task.taskName}</span>
                                                    <span className="mb-3 text-sm text-gray-600 dark:text-gray-400">
                                                        {task.timeLeft >= 60 
                                                            ? `${Math.floor(task.timeLeft / 60)}h ${task.timeLeft % 60}m remaining`
                                                            : `${task.timeLeft} minutes remaining`
                                                        }
                                                    </span>
                                                    <div className="flex justify-end gap-2">
                                                        <button
                                                            onClick={() => handleStartTask(task)}
                                                            className="px-4 py-2 text-sm font-medium text-white transition-all rounded-md bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700"
                                                        >
                                                            Start
                                                        </button>
                                                        <button
                                                            onClick={() => handleDeleteTask(task.taskId)}
                                                            className="p-2 text-white transition-all rounded-md bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700"
                                                            title="Delete task"
                                                        >
                                                            <FaTrash className="w-4 h-4" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div 
                    className={`fixed top-[64px] right-0 h-[calc(100vh-64px)] w-full md:w-80 bg-white/95 dark:bg-gray-800/95 
                               transform transition-transform duration-300 ease-in-out z-[201]
                               ${isCompletedTasksSidebarOpen ? 'translate-x-0' : 'translate-x-full'}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="h-full p-6 overflow-y-auto">
                        <div className="flex items-center">
                            <button 
                                onClick={() => setIsCompletedTasksSidebarOpen(false)}
                                className="p-2 text-gray-800 transition-colors rounded-full dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                            >
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>

                        <div className="mt-4 text-gray-800 dark:text-white">
                            <h2 className="mb-4 text-2xl font-bold">Completed Tasks</h2>
                            <div className="space-y-3">
                                {completedTasks.length === 0 ? (
                                    <p className="text-gray-500 dark:text-gray-400">No completed tasks</p>
                                ) : (
                                    completedTasks.map(task => (
                                        <div 
                                            key={task.taskId} 
                                            className="p-4 transition-all duration-200 rounded-lg bg-white/10 backdrop-blur-sm hover:bg-white/20 dark:bg-gray-700/50 dark:hover:bg-gray-700/70"
                                        >
                                            <div className="flex flex-col">
                                                <span className="mb-1 text-lg font-semibold">{task.taskName}</span>
                                                <span className="mb-3 text-sm text-gray-600 dark:text-gray-400">
                                                    {task.duration >= 60 
                                                        ? `Completed (${Math.floor(task.duration / 60)}h ${task.duration % 60}m)`
                                                        : `Completed (${task.duration} minutes)`
                                                    }
                                                </span>
                                                <div className="flex justify-end">
                                                    <button
                                                        onClick={() => handleDeleteTask(task.taskId, true)}
                                                        className="p-2 text-white transition-all rounded-md bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700"
                                                        title="Delete completed task"
                                                    >
                                                        <FaTrash className="w-4 h-4" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {(isSidebarOpen || isTasksSidebarOpen || isCompletedTasksSidebarOpen) && (
                    <div 
                        className="fixed inset-0 bg-black/30 z-[200]"
                        onClick={() => {
                            setIsSidebarOpen(false);
                            setIsTasksSidebarOpen(false);
                            setIsCompletedTasksSidebarOpen(false);
                        }}
                        aria-label="Close settings"
                    />
                )}
            </div>
        </div>
    );
};

export default RevisionTimer;