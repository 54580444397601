import HCaptcha from "@hcaptcha/react-hcaptcha";
import axios from "axios";
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useTheme } from "../../components/ThemeContext";
import { AuthAPIUrls, VerificationAPIUrls } from "../../utils/APIUrls";
import { getCurrentButtonStyles, getCurrentInputStyles, getCurrentStyles } from "../../utils/Common";
import { initializeGoogleSignIn } from '../../utils/googleSignIn';
import Url, { getWebsiteUrl } from "../../utils/Url";
import { validateEmail, validateHCaptcha, validateHCaptchaError } from "../../utils/validationUtils";

const Login: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [hcaptchaToken, setHecaptchaToken] = useState<string>('');
    const [isVerifying, setIsVerifying] = useState<boolean>(false);
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const navigate = useNavigate();
    const { isDarkMode } = useTheme();

    useEffect(() => {
        initializeGoogleSignIn(process.env.REACT_APP_GOOGLE_CLIENT_ID || '', navigate, setError);
    }, [navigate]);

    const handleVerificationSuccess = async (token: string) => {
        setIsVerifying(true);
        try {
            const response = await axios.post(VerificationAPIUrls.VERIFY_HCAPTCHA, {
                hcaptchaToken: token,
                url: getWebsiteUrl()
            });
            
            if (response.data?.success) {
                setHecaptchaToken(token);
                setError('');
            } else {
                setError(response.data?.message || 'CAPTCHA verification failed');
            }
        } catch (error) {
            setError(validateHCaptchaError(error));
        } finally {
            setIsVerifying(false);
        }
    };

    const handleLogin = async () => {
        const emailError = validateEmail(email);
        if (emailError) {
            setError(emailError);
            return;
        }

        if (!password.trim()) {
            setError('Password cannot be empty');
            return;
        }

        const captchaError = validateHCaptcha(hcaptchaToken);
        if (captchaError) {
            setError(captchaError);
            return;
        }

        setIsLoggingIn(true);
        try {
            const response = await axios.post(AuthAPIUrls.LOGIN, {
                email,
                password,
                hcaptchaToken,
                url: getWebsiteUrl()
            });
    
            if (response.status === 202) {
                navigate(`/verification/${email}/login`);
            } else {
                throw new Error('Unexpected response from server');
            }
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            const apiErrors = error.response.data?.errors;
                            if (apiErrors) {
                                const errorMessages = Object.values(apiErrors).join(' ');
                                setError(errorMessages);
                            } else {
                                setError('Invalid data submitted. Please check your inputs.');
                            }
                            break;
                        case 404:
                            setError('Email does not exist. Please check and try again.');
                            break;
                        case 403:
                            setError('This email is linked to OAuth authentication. Please login with Google or reset your password.');
                            break;
                        case 401:
                            setError('Invalid password. Please check and try again.');
                            break;
                        default:
                            setError(error.response.data || 'An error occurred during login');
                            break;
                    }
                } else if (error.request) {
                    setError('No response from server. Please check your connection.');
                } else {
                    setError('An unexpected error occurred');
                }
            } else {
                setError('An unexpected error occurred');
            }
        } finally {
            setIsLoggingIn(false);
        }
    };
    return <>
        <Helmet>
            <title>Revise Wizard - Login</title>
            <meta name="description" content="Login to your Revise Wizard account"/>
            <link rel="canonical" href={getWebsiteUrl() + Url.LOGIN}/>
        </Helmet>
        <div className="flex flex-col items-center justify-center flex-grow py-6 overflow-hidden md:py-8"
             style={getCurrentStyles(isDarkMode)}>
            <motion.h1
                initial={{opacity: 0, y: -20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
                className="mb-8 text-4xl font-extrabold text-center"
                style={{color: getCurrentStyles(isDarkMode).color}}
            >
                Login
            </motion.h1>

            <div id="google-signin-button"></div>

            {error && (
                <div className="px-4 py-2 mb-4 text-center text-white bg-red-500 rounded w-96">
                    {error}
                </div>
            )}

            <div className="mb-4 w-96">
                <label className="block mb-2 text-lg" style={{color: getCurrentStyles(isDarkMode).color}}>Email:</label>
                <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 rounded"
                    style={getCurrentInputStyles(isDarkMode)}
                />
            </div>

            <div className="mb-2 w-96">
                <label className="block mb-2 text-lg"
                       style={{color: getCurrentStyles(isDarkMode).color}}>Password:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-4 py-2 rounded"
                    style={getCurrentInputStyles(isDarkMode)}
                />
            </div>


            <div className="mb-4 text-center w-96">
                <Link to="/reset-password-request"
                      className="transition duration-300 hover"
                      style={{color: isDarkMode ? '#61892F' : '#000000'}}
                >
                    Forgot your password? Click here
                </Link>
            </div>
            <div className="mb-4">
                <HCaptcha
                    sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY || ''}
                    onVerify={(token) => handleVerificationSuccess(token)}
                />
            </div>

            <motion.button
                whileHover={{scale: 1.05}}
                whileTap={{scale: 0.95}}
                className="px-4 py-2 transition duration-300 rounded"
                onClick={handleLogin}
                style={getCurrentButtonStyles(isDarkMode)}
                disabled={isVerifying || isLoggingIn}
            >
                {isVerifying ? 'Please wait...' : isLoggingIn ? (
                    <div className="flex space-x-1" style={{color: getCurrentStyles(isDarkMode).color}}>
                        <span className="animate-bounce">.</span>
                        <span className="animate-bounce animation-delay-200">.</span>
                        <span className="animate-bounce animation-delay-400">.</span>
                    </div>
                ) : 'Login'}
            </motion.button>
        </div>
    </>;
};

export default Login;
