import React, {useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {KeyRound, AlertCircle, Check, User} from 'lucide-react';
import { useSpring, animated } from 'react-spring';
import {AuthAPIUrls, UserInfoAPIUrls} from "../../utils/APIUrls";
import {useTheme} from "../../components/ThemeContext";
import {getCookie} from "../../utils/Cookies";
import Url, {getWebsiteUrl} from "../../utils/Url";
import {getCurrentStyles} from "../../utils/Common";
import {validatePassword, validatePasswordMatch} from "../../utils/validationUtils";

interface PasswordChangeForm {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

interface UsernameUpdateRequest {
    name: string;
}


const SettingsPage = () => {
    const { isDarkMode } = useTheme();
    const jwtToken = getCookie('jwt');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [isPasswordNull, setIsPasswordNull] = useState<boolean | null>(null);
    const [passwordForm, setPasswordForm] = useState<PasswordChangeForm>({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [username, setUsername] = useState<string>('');
    const [usernameError, setUsernameError] = useState<string | null>(null);
    const [usernameSuccess, setUsernameSuccess] = useState<string | null>(null);

    const fadeIn = useSpring({
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { tension: 280, friction: 20 }
    });

    useEffect(() => {
        const checkPasswordStatus = async () => {
            try {
                const response = await fetch(AuthAPIUrls.IS_PASSWORD_NULL, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwtToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to check password status');
                }

                const isNull = await response.json();
                setIsPasswordNull(isNull);
            } catch (err) {
                setError('Failed to check password status');
            }
        };

        checkPasswordStatus();
    }, [jwtToken]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPasswordForm(prev => ({
            ...prev,
            [name]: value
        }));
        setError(null);
        setSuccess(null);
    };

    const validateForm = (): boolean => {
        const passwordError = validatePassword(passwordForm.newPassword);
        if (passwordError) {
            setError(passwordError);
            return false;
        }

        const passwordMatchError = validatePasswordMatch(passwordForm.newPassword, passwordForm.confirmPassword);
        if (passwordMatchError) {
            setError(passwordMatchError);
            return false;
        }

        return true;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!validateForm()) return;

        setLoading(true);
        try {
            const response = await fetch(AuthAPIUrls.CHANGE_PASSWORD, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`,
                },
                body: JSON.stringify({
                    currentPassword: isPasswordNull ? null : passwordForm.currentPassword,
                    newPassword: passwordForm.newPassword
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to change password');
            }

            setSuccess('Password successfully changed');
            setPasswordForm({
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            });
            setIsPasswordNull(false);
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                setError('Failed to change password. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    if (isPasswordNull === null) {
        return (
            <div className="flex items-center justify-center h-screen" style={getCurrentStyles(isDarkMode)}>
                <div className="w-12 h-12 border-4 border-green-600 rounded-full animate-spin border-t-transparent"></div>
            </div>
        );
    }

    const handleUsernameSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const requestBody: UsernameUpdateRequest = {
                name: username
            };

            const response = await fetch(UserInfoAPIUrls.NAME, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`,
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update username');
            }

            setUsernameSuccess('Username successfully updated');
            setUsername('');
        } catch (err) {
            if (err instanceof Error) {
                setUsernameError(err.message);
            } else {
                setUsernameError('Failed to update username. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <Helmet>
                <title>Revise Wizard - Settings</title>
                <meta name="description" content="Manage your Revise Wizard account settings" />
                <link rel="canonical" href={getWebsiteUrl() + Url.SETTINGS_PAGE} />
            </Helmet>

            <div className="flex flex-col items-center flex-grow" style={getCurrentStyles(isDarkMode)}>
                <div className="w-full max-w-2xl px-4 py-6 md:py-8">
                    <animated.div style={fadeIn} className="mb-6 text-center">
                        <h1 className="text-3xl font-bold">Account Settings</h1>
                        <p className="mt-2">
                            Manage your account preferences and security
                        </p>
                    </animated.div>

                    {/* Username Section */}
                    <animated.div style={fadeIn}
                                  className="p-6 mb-6 bg-white shadow-lg dark:bg-gray-800 rounded-xl backdrop-blur-sm bg-opacity-90 dark:bg-opacity-90">
                        <div className="flex items-center mb-6 space-x-4">
                            <div className="p-3 bg-blue-100 rounded-lg dark:bg-blue-900">
                                <User className="w-6 h-6 text-blue-600 dark:text-blue-400"/>
                            </div>
                            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Update Username
                            </h2>
                        </div>

                        <form onSubmit={handleUsernameSubmit} className="space-y-6" autoComplete="off">
                            <div>
                                <label htmlFor="display-name"
                                       className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    New Username
                                </label>
                                <input
                                    type="text"
                                    id="display-name"
                                    name="display-name"
                                    autoComplete="off"
                                    spellCheck="false"
                                    value={username}
                                    onChange={(e) => {
                                        setUsername(e.target.value);
                                        setUsernameError(null);
                                        setUsernameSuccess(null);
                                    }}
                                    className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                    required
                                />
                            </div>

                            {usernameError && (
                                <div className="flex items-center space-x-2 text-red-600 dark:text-red-400">
                                    <AlertCircle className="w-5 h-5"/>
                                    <span>{usernameError}</span>
                                </div>
                            )}

                            {usernameSuccess && (
                                <div className="flex items-center space-x-2 text-green-600 dark:text-green-400">
                                    <Check className="w-5 h-5"/>
                                    <span>{usernameSuccess}</span>
                                </div>
                            )}

                            <button
                                type="submit"
                                disabled={loading}
                                className="flex items-center justify-center w-full px-4 py-2 space-x-2 font-semibold text-white transition duration-300 rounded-md bg-gradient-to-r from-green-600 to-green-500 hover:from-green-700 hover:to-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {loading ? (
                                    <div
                                        className="w-5 h-5 border-2 border-white rounded-full animate-spin border-t-transparent"></div>
                                ) : (
                                    'Update Username'
                                )}
                            </button>
                        </form>
                    </animated.div>

                    {/* Password Section */}
                    <animated.div style={fadeIn}
                                  className="p-6 bg-white shadow-lg dark:bg-gray-800 rounded-xl backdrop-blur-sm bg-opacity-90 dark:bg-opacity-90">
                        <div className="flex items-center mb-6 space-x-4">
                            <div className="p-3 bg-blue-100 rounded-lg dark:bg-blue-900">
                                <KeyRound className="w-6 h-6 text-blue-600 dark:text-blue-400"/>
                            </div>
                            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                                {isPasswordNull ? 'Set Password' : 'Change Password'}
                            </h2>
                        </div>

                        <form onSubmit={handleSubmit} className="space-y-6">
                            {!isPasswordNull && (
                                <div>
                                    <label htmlFor="currentPassword"
                                           className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                        Current Password
                                    </label>
                                    <input
                                        type="password"
                                        id="currentPassword"
                                        name="currentPassword"
                                        value={passwordForm.currentPassword}
                                        onChange={handleInputChange}
                                        className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                        required={!isPasswordNull}
                                    />
                                </div>
                            )}

                            <div>
                                <label htmlFor="newPassword"
                                       className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    New Password
                                </label>
                                <input
                                    type="password"
                                    id="newPassword"
                                    name="newPassword"
                                    value={passwordForm.newPassword}
                                    onChange={handleInputChange}
                                    className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                    required
                                />
                            </div>

                            <div>
                                <label htmlFor="confirmPassword"
                                       className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Confirm New Password
                                </label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    value={passwordForm.confirmPassword}
                                    onChange={handleInputChange}
                                    className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                    required
                                />
                            </div>

                            {error && (
                                <div className="flex items-center space-x-2 text-red-600 dark:text-red-400">
                                    <AlertCircle className="w-5 h-5"/>
                                    <span>{error}</span>
                                </div>
                            )}

                            {success && (
                                <div className="flex items-center space-x-2 text-green-600 dark:text-green-400">
                                    <Check className="w-5 h-5"/>
                                    <span>{success}</span>
                                </div>
                            )}

                            <button
                                type="submit"
                                disabled={loading}
                                className="flex items-center justify-center w-full px-4 py-2 space-x-2 font-semibold text-white transition duration-300 rounded-md bg-gradient-to-r from-green-600 to-green-500 hover:from-green-700 hover:to-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {loading ? (
                                    <div
                                        className="w-5 h-5 border-2 border-white rounded-full animate-spin border-t-transparent"></div>
                                ) : (
                                    isPasswordNull ? 'Set Password' : 'Change Password'
                                )}
                            </button>
                        </form>
                    </animated.div>
                </div>
            </div>
        </>
    );
};

export default SettingsPage;